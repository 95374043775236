










































































































import { Component, Prop, Ref, Vue, Watch } from "vue-property-decorator";

import {
  AppSectionAggregator,
  PermissionAggregatorNames,
  PermissionEntity,
  PermissionEntityNames,
  PermissionFeatures,
  PermissionRequestModel,
  PermissionResponseModel,
} from "@/iam/models/PermissionModel";
import { ReportType } from "@/reports/models";
import ReportUtil from "@/reports/utils/ReportUtil";
import {
  GENERAL_SECTIONS,
  UserAccess,
  UserResponseModel,
} from "@/shared/models";
import { VueForm } from "@/shared/types/ExtendedVueType";
import ValidUtil from "@/shared/utils/ValidUtil";
import { PermissionUtil } from "../utils/PermissionUtil";

@Component
export default class PermissionForm extends Vue {
  @Prop() value!: boolean;
  @Prop() user!: UserResponseModel;
  @Prop() application?: string;
  @Prop({ default: "" }) title!: string;
  @Prop() permissionEntity!:
    | PermissionEntity.AD_NETWORK
    | PermissionEntity.APP_SECTION;

  @Ref("form") form!: VueForm;

  readonly requiredRule = [
    ValidUtil.required(this.$lang("validation.required")),
  ];
  readonly requiredArray = [
    ValidUtil.requiredArray(this.$lang("validation.required")),
  ];
  validPermission = true;
  localPermission: PermissionRequestModel | null = null;

  get permissionEntityNames(): Array<PermissionEntityNames> {
    return this.$store.state.usersStore.permissionEntityNames;
  }

  get aggregatorNames(): Array<PermissionAggregatorNames> {
    return this.$store.state.usersStore.aggregatorNames;
  }

  get permissionFeatures(): Array<PermissionFeatures> {
    return this.$store.state.usersStore.permissionFeatures;
  }

  get applications(): Array<Record<string, string | boolean>> {
    const appIds: Array<string> =
      this.permissionEntityNames.find(
        ({ ngacEntityName }: PermissionEntityNames) =>
          ngacEntityName === PermissionEntity.APPLICATION
      )?.ngacNames || [];
    const aggregatorNames: Array<string> =
      this.aggregatorNames
        .find(
          ({ ngacEntityName }: PermissionAggregatorNames) =>
            ngacEntityName === PermissionEntity.APPLICATIONS
        )
        ?.aggregators.map(({ name }) => name) || [];

    return [
      ...aggregatorNames
        .map((value) => ({
          text: value,
          value,
          isAggregator: true,
        }))
        .sort(this.sortByName),
      ...appIds
        .map((value) => ({
          text: this.$store.getters.applicationsById.get(value)?.name || value,
          value,
        }))
        .sort(this.sortByName),
    ];
  }

  get aggregatorEntity(): PermissionEntity | undefined {
    return PermissionUtil.getAggregatorByEntity(this.permissionEntity);
  }

  get unavailablePermissionValues(): Array<string> {
    const application = this.localPermission?.application;

    if (!application || !this.user.permissionsByApps[application]) {
      return [];
    }

    if (this.permissionEntity === PermissionEntity.AD_NETWORK) {
      return this.user.permissionsByApps[application].map(({ value }) => value);
    }

    return [];
  }

  get featuresByName(): Record<string, Array<UserAccess>> {
    return this.$store.getters.featuresByName;
  }

  get userFeaturesByName(): Record<string, Array<UserAccess>> {
    const application = this.localPermission?.application;

    if (!application || !this.user.permissionsByApps[application]) {
      return {};
    }

    return this.user.permissionsByApps[application].reduce(
      (result: Record<string, Array<UserAccess>>, { value, feature }) => {
        if (result[value]) {
          result[value].push(feature);
        } else {
          result[value] = [feature];
        }

        return result;
      },
      {}
    );
  }

  get values(): Array<{ value: string; text: string; isAggregator?: boolean }> {
    const aggregators: Array<{
      value: string;
      text: string;
      isAggregator?: boolean;
    }> =
      this.aggregatorNames
        .find(
          ({ ngacEntityName }: PermissionAggregatorNames) =>
            ngacEntityName === this.aggregatorEntity
        )
        ?.aggregators.flatMap(({ name: value, type }) => {
          if (this.isAdNetworkPermission) {
            return this.unavailablePermissionValues.includes(value)
              ? []
              : [
                  {
                    text: value,
                    value,
                    isAggregator: true,
                  },
                ];
          }

          return type !== AppSectionAggregator.GENERAL_APP_SECTIONS &&
            this.featuresByName[value]?.length !==
              this.userFeaturesByName[value]?.length
            ? [
                {
                  text: value,
                  value,
                  isAggregator: true,
                },
              ]
            : [];
        })
        .sort(this.sortByName) || [];
    const entities: Array<{
      value: string;
      text: string;
      isAggregator?: boolean;
    }> =
      this.permissionEntityNames
        .find(
          ({ ngacEntityName }: PermissionEntityNames) =>
            ngacEntityName === this.permissionEntity
        )
        ?.ngacNames.flatMap((value: string) => {
          if (this.isAdNetworkPermission) {
            return this.unavailablePermissionValues.includes(value)
              ? []
              : [
                  {
                    text: this.getValueTitle(value),
                    value,
                  },
                ];
          }

          return !GENERAL_SECTIONS.includes(value) &&
            this.featuresByName[value]?.length !==
              this.userFeaturesByName[value]?.length
            ? [
                {
                  text: this.getValueTitle(value),
                  value,
                },
              ]
            : [];
        })
        .sort(this.sortByName) || [];

    return aggregators.concat(entities);
  }

  get valueLabel(): string {
    return this.$lang(
      `users.valueLabel.${this.permissionEntity.toLowerCase()}`
    );
  }

  get isAdNetworkPermission(): boolean {
    return this.permissionEntity === PermissionEntity.AD_NETWORK;
  }

  get features(): Array<Record<string, string>> {
    const application = this.localPermission?.application;
    const permissions = application
      ? this.user.permissionsByApps[application] || []
      : [];
    const unavailableFeatures = permissions.reduce(
      (
        result: Array<UserAccess>,
        { value, feature }: PermissionResponseModel
      ) => {
        if (value === this.localPermission?.value) {
          result.push(feature);
        }

        return result;
      },
      []
    );
    const availableFeatures: Array<UserAccess> =
      this.permissionFeatures
        .find(
          ({ ngacName }: PermissionFeatures) =>
            ngacName === this.localPermission?.value
        )
        ?.features.filter(
          (feature) => !unavailableFeatures.includes(feature)
        ) ?? [];

    return availableFeatures.map((value) => ({
      text: this.$lang(`users.userAccess.${value.toLowerCase()}`),
      value,
    }));
  }

  get visible(): boolean {
    return this.value;
  }

  set visible(value: boolean) {
    this.$emit("input", value);
  }

  get isPermissionAdding(): boolean {
    return this.$store.state.usersStore.isPermissionAdding;
  }

  @Watch("localPermission.application", { immediate: true })
  updateApplicationAggregator(newValue?: string) {
    if (!this.localPermission || !newValue) {
      return;
    }

    this.localPermission.isApplicationAggregator = this.applications.some(
      ({ value, isAggregator }) => value === newValue && isAggregator
    );
  }

  @Watch("localPermission.application")
  @Watch("localPermission.value")
  resetFeatures(_: string, oldValue?: string) {
    if (!this.localPermission || !oldValue) {
      return;
    }

    this.localPermission.features = [];
  }

  @Watch("localPermission.value")
  watchValue(newValue: string | undefined) {
    if (!this.localPermission) {
      return;
    }

    if (!newValue) {
      this.localPermission.entity = undefined;

      return;
    }

    this.localPermission.isAggregator = this.values.some(
      ({ value, isAggregator }) => value === newValue && isAggregator
    );
    this.localPermission.entity = this.values.find(
      ({ value }) => value === newValue
    )?.isAggregator
      ? this.aggregatorEntity
      : this.permissionEntity;

    if (this.isAdNetworkPermission) {
      this.localPermission.features = [UserAccess.VIEW];
    }
  }

  @Watch("visible")
  watchVisibility() {
    if (!this.visible) {
      this.localPermission = null;

      return;
    }

    const application: string =
      this.application ?? this.$store.state.application.applicationId;

    this.localPermission = new PermissionRequestModel(
      this.user.username,
      application
    );
  }

  getValueTitle(value: string): string {
    if (this.permissionEntity !== PermissionEntity.APP_SECTION) {
      return value;
    }

    return Object.values(ReportType).includes(value as ReportType)
      ? ReportUtil.getReportNameByReportType(value as ReportType)
      : this.$lang(`users.appSection.${value.toLowerCase()}`);
  }

  async addPermission() {
    if (!this.form.validate()) {
      return;
    }

    await this.$store.dispatch(
      "addUserPermission",
      this.localPermission?.permissionsRequest
    );
    this.$emit("input", false);
  }

  sortByName(
    { text: textA }: { text: string },
    { text: textB }: { text: string }
  ) {
    const nameA = textA.toLowerCase();
    const nameB = textB.toLowerCase();

    if (nameA < nameB) {
      return -1;
    }

    return nameA > nameB ? 1 : 0;
  }
}
