
























































































import { Component, Prop, Ref, Vue, Watch } from "vue-property-decorator";

import {
  PermissionExportCopyMode,
  PermissionExportInsertMode,
  PermissionExportModel,
  PermissionExportType,
} from "@/iam/models/PermissionExportModel";
import {
  PermissionAggregatorNames,
  PermissionEntity,
  PermissionEntityNames,
} from "@/iam/models/PermissionModel";
import { UserModel } from "@/shared/models";
import { VueForm } from "@/shared/types/ExtendedVueType";
import ValidUtil from "@/shared/utils/ValidUtil";

@Component
export default class PermissionExportDialog extends Vue {
  @Prop() value!: boolean;
  @Ref("form") form!: VueForm;

  readonly requiredRule = [
    ValidUtil.required(this.$lang("validation.required")),
  ];
  readonly insertModes = Object.values(PermissionExportInsertMode).map(
    (value: string) => ({
      value,
      text: this.$lang(
        `users.permissionsExport.insertMode.${value.toLowerCase()}`
      ),
    })
  );
  readonly copyModes = Object.values(PermissionExportCopyMode).map(
    (value: string) => ({
      value,
      text: this.$lang(
        `users.permissionsExport.copyMode.${value.toLowerCase()}`
      ),
    })
  );
  readonly types = Object.values(PermissionExportType).map((value: string) => ({
    value,
    text: this.$lang(`users.permissionsExport.type.${value.toLowerCase()}`),
  }));

  valid = true;
  permissionExport = new PermissionExportModel(this.username);

  get username(): string {
    return this.$store.state.usersStore.user.username;
  }

  get visible(): boolean {
    return this.value;
  }

  set visible(newValue: boolean) {
    this.$emit("input", newValue);
  }

  get applicationItems(): Array<Record<string, any>> {
    const applications: Array<string> =
      this.$store.state.usersStore.permissionEntityNames.find(
        ({ ngacEntityName }: PermissionEntityNames) =>
          ngacEntityName === PermissionEntity.APPLICATION
      ).ngacNames;
    const aggregators: Array<string> =
      (
        this.$store.state.usersStore
          .aggregatorNames as Array<PermissionAggregatorNames>
      )
        .find(
          ({ ngacEntityName }: PermissionAggregatorNames) =>
            ngacEntityName === PermissionEntity.APPLICATIONS
        )
        ?.aggregators.map(({ name }) => name) || [];

    return [
      ...(aggregators.length
        ? [{ header: this.$lang("users.permissionsExport.applications") }]
        : []),
      ...applications
        .filter((value) => !aggregators.includes(value))
        .map((value) => ({
          value: {
            ngacName: value,
            ngacEntityName: PermissionEntity.APPLICATION,
          },
          text: this.$store.getters.applicationsById.get(value)?.name || value,
        })),
      ...(aggregators.length
        ? [
            { divider: true },
            {
              header: this.$lang("users.applicationAggregators"),
            },
          ]
        : []),
      ...aggregators.map((value) => ({
        value: {
          ngacName: value,
          ngacEntityName: PermissionEntity.APPLICATIONS,
        },
        text: value,
      })),
    ];
  }

  get users(): Array<Record<string, string>> {
    return this.$store.state.usersStore.users.flatMap(
      ({ isSuperUser, username, fullName }: UserModel) =>
        isSuperUser || username === this.username
          ? []
          : [
              {
                value: username,
                text: fullName,
              },
            ]
    );
  }

  get isPermissionsCopying(): boolean {
    return this.$store.state.usersStore.isPermissionsCopying;
  }

  get applicationsVisible(): boolean {
    return (
      this.permissionExport.isGranulated &&
      this.permissionExport.type !== PermissionExportType.GENERAL_SECTIONS
    );
  }

  @Watch("value")
  watchValue(value: boolean) {
    if (!value) {
      this.form.resetValidation();

      return;
    }

    this.permissionExport = new PermissionExportModel(this.username);
  }

  async submit() {
    if (!this.form.validate()) {
      return;
    }

    await this.$store.dispatch(
      "exportPermissions",
      this.permissionExport.getRequest()
    );
    this.visible = false;
  }
}
