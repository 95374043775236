import { PermissionLink } from "./PermissionModel";

export enum PermissionExportInsertMode {
  APPEND = "APPEND",
  REPLACE = "REPLACE",
}

export enum PermissionExportCopyMode {
  FULL = "FULL",
  GRANULATED = "GRANULATED",
}

export enum PermissionExportType {
  AD_NETWORK = "AD_NETWORK",
  NONE_GENERAL_SECTIONS = "NONE_GENERAL_SECTIONS",
  GENERAL_SECTIONS = "GENERAL_SECTIONS",
}

export interface PermissionExportRequest {
  sourceUsername: string;
  targetUsername: string;
  insertMode: PermissionExportInsertMode;
  copyMode: PermissionExportCopyMode;
  type?: PermissionExportType;
  applications?: Array<PermissionLink>;
}

export class PermissionExportModel {
  constructor(
    public sourceUsername: string,
    public targetUsername?: string,
    public insertMode = PermissionExportInsertMode.APPEND,
    public copyMode = PermissionExportCopyMode.FULL,
    public type = PermissionExportType.AD_NETWORK,
    public applications: Array<PermissionLink> = []
  ) {}

  get isGranulated(): boolean {
    return this.copyMode === PermissionExportCopyMode.GRANULATED;
  }

  getRequest(): PermissionExportRequest {
    const request: PermissionExportRequest = {
      sourceUsername: this.sourceUsername,
      targetUsername: this.targetUsername || "",
      insertMode: this.insertMode,
      copyMode: this.copyMode,
    };

    if (this.isGranulated) {
      request.type = this.type;

      if (this.type !== PermissionExportType.GENERAL_SECTIONS) {
        request.applications = this.applications;
      }
    }

    return request;
  }
}
