var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-tab-item',{attrs:{"value":_vm.permissionEntity}},[_c('v-data-table',{attrs:{"headers":_vm.permissionsHeaders,"items":_vm.groupedGeneralPermissions,"group-by":"isAggregator","group-desc":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-spacer'),_c('v-menu',{attrs:{"close-on-content-click":false,"close-on-click":false,"min-width":580},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","fab":"","small":""},on:{"click":function($event){_vm.permissionDialogVisible = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-plus ")])],1)]}}]),model:{value:(_vm.permissionDialogVisible),callback:function ($$v) {_vm.permissionDialogVisible=$$v},expression:"permissionDialogVisible"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$lang("users.addGeneralPermission"))+" ")]),_c('v-card-text',[_c('v-form',{ref:"form",model:{value:(_vm.validPermission),callback:function ($$v) {_vm.validPermission=$$v},expression:"validPermission"}},[_c('v-autocomplete',{staticClass:"mb-3",attrs:{"items":_vm.values,"label":_vm.$lang('users.valueLabel.app_section'),"rules":_vm.requiredRule,"hide-details":"auto","dense":"","outlined":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-title',[_vm._v(" "+_vm._s(item.text)+" "),(item.isAggregator)?_c('v-chip',{staticClass:"px-2 ml-2",attrs:{"outlined":"","small":"","label":""}},[_vm._v(_vm._s(_vm.$lang("users.aggregator")))]):_vm._e()],1)]}}]),model:{value:(_vm.localPermission.value),callback:function ($$v) {_vm.$set(_vm.localPermission, "value", $$v)},expression:"localPermission.value"}}),_c('v-autocomplete',{attrs:{"label":_vm.$lang('users.feature'),"items":_vm.features,"rules":_vm.requiredArray,"disabled":!_vm.localPermission.value,"hide-details":"auto","dense":"","outlined":"","multiple":""},model:{value:(_vm.localPermission.features),callback:function ($$v) {_vm.$set(_vm.localPermission, "features", $$v)},expression:"localPermission.features"}})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.permissionDialogVisible = false}}},[_vm._v(" "+_vm._s(_vm.$lang("btn.cancel"))+" ")]),_c('v-btn',{attrs:{"disabled":!_vm.validPermission || _vm.isPermissionAdding,"loading":_vm.isPermissionAdding,"color":"primary","text":""},on:{"click":_vm.addPermission}},[_vm._v(" "+_vm._s(_vm.$lang("btn.add"))+" ")])],1)],1)],1)],1)]},proxy:true},{key:"group.header",fn:function(){return undefined},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
var headers = ref.headers;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('tr',_vm._l((headers),function(ref){
var value = ref.value;
return _c('td',{key:value,class:[value === 'value' && 'vertical-align-top'],staticStyle:{"line-height":"1.5rem"}},[(value === 'value')?_c('div',{staticClass:"mt-3 mb-2"},[_vm._v(" "+_vm._s(item.isAggregator ? item.value : _vm.getValueTitle(item.value))+" "),(item.isAggregator)?_c('v-chip',{attrs:{"small":"","outlined":"","label":""}},[_vm._v(" "+_vm._s(_vm.$lang("users.aggregator"))+" ")]):_vm._e()],1):_vm._e(),(value === 'features')?_c('ul',{staticClass:"ma-0 px-0 py-2",staticStyle:{"list-style":"none"}},_vm._l((item.permissions),function(ref){
var feature = ref.feature;
var id = ref.id;
return _c('li',{key:feature,staticClass:"d-flex justify-space-between align-center"},[_vm._v(" "+_vm._s(_vm.$lang(("users.userAccess." + (feature.toLowerCase()))))+" "),_c('v-btn',{class:['ml-1', !hover && 'visibility-hidden'],attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.deletePermission(id)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)}),0):_vm._e()])}),0)]}}],null,true)})]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }