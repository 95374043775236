



















































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import PermissionForm from "@/iam/components/PermissionForm.vue";
import {
  PermissionAggregatorNames,
  PermissionEntity,
  PermissionResponseGroup,
  PermissionResponseModel,
} from "@/iam/models/PermissionModel";
import { PermissionUtil } from "@/iam/utils/PermissionUtil";
import { ReportType } from "@/reports/models";
import ReportUtil from "@/reports/utils/ReportUtil";
import ConfirmationDialog from "@/shared/components/ConfirmationDialog.vue";
import { UserResponseModel } from "@/shared/models";

@Component({
  components: { PermissionForm, ConfirmationDialog },
})
export default class UserPermissionList extends Vue {
  @Prop() searchApplication!: string;
  @Prop() permissionEntity!: PermissionEntity;

  specificAppPermissionDialog: string | null = null;
  permissionDialogVisible = false;

  get user(): UserResponseModel {
    return this.$store.state.usersStore.user;
  }

  get userName(): string {
    return this.$route.params.user;
  }

  get appAggregators(): Array<string> {
    return (
      this.$store.state.usersStore.aggregatorNames.find(
        ({ ngacEntityName }: PermissionAggregatorNames) =>
          ngacEntityName === PermissionEntity.APPLICATIONS
      )?.aggregators ?? []
    );
  }

  get permissionsByApps(): Array<[string, Array<PermissionResponseModel>]> {
    const searchApplication = this.searchApplication.toLowerCase();
    const aggregatorEntity = PermissionUtil.getAggregatorByEntity(
      this.permissionEntity
    );
    const entities = [
      ...(aggregatorEntity ? [aggregatorEntity] : []),
      this.permissionEntity,
    ];

    return this.user?.permissionsByApps
      ? Object.entries(this.user.permissionsByApps)
          .reduce(
            (
              result: Array<[string, Array<PermissionResponseModel>]>,
              [app, permissions]
            ) => {
              if (
                !app.includes(searchApplication) &&
                !this.getApplicationNameById(app)
                  .toLowerCase()
                  .includes(searchApplication)
              ) {
                return result;
              }

              const localPermissions = permissions.filter(({ entity }) =>
                entities.includes(entity)
              );

              if (localPermissions.length) {
                result.push([app, localPermissions]);
              }

              return result;
            },
            []
          )
          .sort(([appA], [appB]) => {
            if (this.isAppAggregator(appA) === this.isAppAggregator(appB)) {
              return appA.toLowerCase() < appB.toLowerCase() ? -1 : 1;
            }

            return this.isAppAggregator(appA) ? -1 : 1;
          })
      : [];
  }

  get groupedPermissionsByApps(): Array<
    [string, Array<PermissionResponseGroup>]
  > {
    return this.permissionsByApps.map(([app, permissions]) => [
      app,
      permissions.reduce(
        (
          result: Array<PermissionResponseGroup>,
          permission: PermissionResponseModel
        ) => {
          const groupIndex = result.findIndex(
            ({ value }) => value === permission.value
          );

          if (groupIndex !== -1) {
            result[groupIndex].permissions.push(permission);
            result[groupIndex].permissions.sort(
              ({ feature: featureA }, { feature: featureB }) =>
                featureA > featureB ? 1 : -1
            );
          } else {
            result.push({
              entity: permission.entity,
              value: permission.value,
              permissions: [permission],
              isAggregator: permission.isAggregator,
            });
          }

          return result;
        },
        []
      ),
    ]);
  }

  get permissionsHeaders(): Array<Record<string, any>> {
    return [
      {
        text: this.$lang("users.value"),
        value: "value",
      },
      ...(this.permissionEntity === PermissionEntity.AD_NETWORK
        ? [
            {
              text: this.$lang("users.actions"),
              value: "actions",
              align: "end",
              sortable: false,
              width: "5%",
            },
          ]
        : [
            {
              text: this.$lang("users.features"),
              value: "features",
              sortable: false,
              width: 150,
            },
          ]),
    ];
  }

  @Watch("userName", { immediate: true })
  watchUser() {
    this.hideSpecificAppPermissionForm();
  }

  @Watch("permissionDialogVisible")
  watchDialogVisiblity(value: boolean) {
    if (!value) {
      return;
    }

    this.specificAppPermissionDialog = null;
  }

  getApplicationNameById(appId: string): string {
    return this.$store.getters.applicationsById.get(appId)?.name || appId;
  }

  isAppAggregator(app: string): boolean {
    return this.appAggregators.includes(app);
  }

  showSpecificAppPermissionForm(app: string) {
    this.permissionDialogVisible = false;
    this.specificAppPermissionDialog = app;
  }

  hideSpecificAppPermissionForm() {
    this.permissionDialogVisible = false;
    this.specificAppPermissionDialog = null;
  }

  getValueText({ entity, value }: PermissionResponseGroup): string {
    if (entity !== PermissionEntity.APP_SECTION) {
      return value;
    }

    return value in ReportType
      ? ReportUtil.getReportNameByReportType(value as ReportType)
      : this.$lang(`users.appSection.${value.toLowerCase()}`);
  }

  deletePermission(permissionId: number) {
    this.$emit("delete", permissionId);
  }
}
