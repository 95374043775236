























































































































































































































import { Component, Ref, Vue, Watch } from "vue-property-decorator";

import PermissionExportDialog from "@/iam/components/PermissionExportDialog.vue";
import PermissionForm from "@/iam/components/PermissionForm.vue";
import UserPermissionList from "@/iam/components/UserPermissionList.vue";
import UserPermissionListGeneral from "@/iam/components/UserPermissionListGeneral.vue";
import { PermissionEntity } from "@/iam/models/PermissionModel";
import { PermissionUtil } from "@/iam/utils/PermissionUtil";
import ConfirmationDialog from "@/shared/components/ConfirmationDialog.vue";
import { AppSection, UserModel, UserResponseModel } from "@/shared/models";
import { VueForm } from "@/shared/types/ExtendedVueType";
import ValidUtil from "@/shared/utils/ValidUtil";

@Component({
  components: {
    PermissionForm,
    ConfirmationDialog,
    UserPermissionList,
    UserPermissionListGeneral,
    PermissionExportDialog,
  },
})
export default class UserView extends Vue {
  @Ref("userUpdateForm") userUpdateForm!: VueForm;

  readonly PermissionEntity = PermissionEntity;
  readonly requiredRule = [
    ValidUtil.required(this.$lang("validation.required")),
  ];
  readonly usersPageRoute = {
    name: AppSection.USERS,
  };
  readonly permissionEntities = [
    PermissionEntity.APP_SECTION,
    PermissionEntity.AD_NETWORK,
    PermissionEntity.GENERAL,
  ];
  isUserEditing = false;
  valid = true;
  editingUser = new UserResponseModel();
  userDeleteDialogVisible = false;
  searchApplication = "";
  permissionDialogVisible = false;
  creatingPermission = null;
  permissionDeleteDialogVisible = false;
  deletingPermissionId: number | null = null;
  permissionEntityValue = this.permissionEntities[0];
  permissionExportDialogVisible = false;

  get users(): Array<UserModel> {
    return this.$store.state.usersStore.users;
  }

  get userName(): string {
    return this.$route.params.user;
  }

  get avatarText(): string {
    const { firstName, lastName } = this.user;

    return `${firstName[0].toUpperCase()}${lastName[0].toUpperCase()}`;
  }

  get isSavingInProgress(): boolean {
    return this.$store.state.usersStore.isUserSaving;
  }

  get isUserDeleting(): boolean {
    return this.$store.state.usersStore.isUserDeleting;
  }

  get isNotDeletable(): boolean {
    return this.user.isSuperUser && this.$store.getters.countSuperAdmins === 1;
  }

  get isPermissionDeleting(): boolean {
    return this.$store.state.usersStore.isPermissionDeleting;
  }

  get extendedUser(): UserResponseModel {
    return this.$store.state.usersStore.user;
  }

  get user(): UserResponseModel {
    return (
      this.extendedUser ||
      this.users.find(({ username }) => username === this.userName)
    );
  }

  get isUserLoading(): boolean {
    return this.$store.state.usersStore.isUserLoading;
  }

  get selectedApp(): string | null {
    return Array.isArray(this.$route.query.app)
      ? this.$route.query.app[0]
      : this.$route.query.app;
  }

  @Watch("userName", { immediate: true })
  async watchUser() {
    this.permissionDialogVisible = false;
    this.cancelUserEditing();
    this.$store.commit("setExtendedUser");

    const user = this.users.find(({ username }) => username === this.userName);

    if (user) {
      await this.$store.dispatch("fetchUser", this.userName);
    } else {
      this.$store.dispatch(
        "addError",
        this.$lang("users.error.notFound", this.userName)
      );
      this.goToUserList();
    }
  }

  created() {
    if (this.selectedApp) {
      this.searchApplication = this.selectedApp;
    }
  }

  destroyed() {
    this.$store.commit("setExtendedUser");
  }

  editUser() {
    if (!this.$store.state.usersStore.user) {
      return;
    }

    this.editingUser = UserResponseModel.of(this.$store.state.usersStore.user);
    this.isUserEditing = true;
  }

  cancelUserEditing() {
    this.editingUser = new UserResponseModel();
    this.isUserEditing = false;
  }

  goToUserList() {
    this.$router.push({ name: AppSection.USERS });
  }

  async updateUser() {
    if (!this.userUpdateForm.validate()) {
      return;
    }

    await this.$store.dispatch("updateUser", this.editingUser);
    this.cancelUserEditing();
  }

  async deleteUser() {
    await this.$store.dispatch("deleteUser", this.user.username);
    this.userDeleteDialogVisible = false;
    this.goToUserList();
  }

  showDeletePermissionConfirmDialog(permissionId: number) {
    this.deletingPermissionId = permissionId;
    this.permissionDeleteDialogVisible = true;
  }

  async deleteUserPermission() {
    await this.$store.dispatch(
      "deleteUserPermission",
      this.deletingPermissionId
    );
    this.permissionDeleteDialogVisible = false;
  }

  getTabName(permission: PermissionEntity): string {
    return this.$lang(
      `users.permissionEntity.${PermissionUtil.getAggregatorByEntity(
        permission
      )?.toLowerCase()}`
    );
  }
}
