var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-tab-item',{attrs:{"value":_vm.permissionEntity}},[(_vm.groupedPermissionsByApps.length)?_vm._l((_vm.groupedPermissionsByApps),function(ref){
var app = ref[0];
var groups = ref[1];
return _c('v-data-table',{key:app,attrs:{"headers":_vm.permissionsHeaders,"items":groups,"group-by":"isAggregator","group-desc":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.getApplicationNameById(app)))]),(_vm.isAppAggregator(app))?_c('v-chip',{staticClass:"px-2 ml-2",attrs:{"outlined":"","label":""}},[_vm._v(_vm._s(_vm.$lang("users.aggregator")))]):_vm._e(),_c('v-spacer'),_c('permission-form',{attrs:{"value":_vm.specificAppPermissionDialog === app,"application":app,"title":_vm.$lang('users.newPermissionForApp', _vm.getApplicationNameById(app)),"user":_vm.user,"permission-entity":_vm.permissionEntity},on:{"input":_vm.hideSpecificAppPermissionForm},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","fab":"","small":""},on:{"click":function($event){return _vm.showSpecificAppPermissionForm(app)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-plus ")])],1)]}}],null,true)})],1)]},proxy:true},{key:"group.header",fn:function(){return undefined},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
var headers = ref.headers;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('tr',_vm._l((headers),function(ref){
var value = ref.value;
return _c('td',{key:value,class:[value === 'value' && 'vertical-align-top']},[(value === 'value')?_c('div',{staticClass:"mt-3 mb-2",staticStyle:{"line-height":"1.5rem"}},[_vm._v(" "+_vm._s(_vm.getValueText(item))+" "),(item.isAggregator)?_c('v-chip',{attrs:{"small":"","outlined":"","label":""}},[_vm._v(" "+_vm._s(_vm.$lang("users.aggregator"))+" ")]):_vm._e()],1):_vm._e(),(value === 'features')?_c('ul',{staticClass:"ma-0 px-0 py-2",staticStyle:{"list-style":"none"}},_vm._l((item.permissions),function(ref){
var feature = ref.feature;
var id = ref.id;
return _c('li',{key:feature,staticClass:"d-flex justify-space-between align-center"},[_vm._v(" "+_vm._s(_vm.$lang(("users.userAccess." + (feature.toLowerCase()))))+" "),_c('v-btn',{class:['ml-1', !hover && 'visibility-hidden'],attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.deletePermission(id)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)}),0):_vm._e(),(value === 'actions')?_c('v-btn',{attrs:{"small":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.deletePermission(item.permissions[0].id)}}},[_c('v-icon',[_vm._v("delete")])],1):_vm._e()],1)}),0)]}}],null,true)})]}}],null,true)})}):_c('div',{staticClass:"mt-5 text-center body-2 grey--text"},[_vm._v(" "+_vm._s(_vm.$lang("noDataText"))+" ")])],2)}
var staticRenderFns = []

export { render, staticRenderFns }