


















































































































































import { Component, Prop, Ref, Vue, Watch } from "vue-property-decorator";

import {
  AppSectionAggregator,
  PermissionAggregatorNames,
  PermissionEntity,
  PermissionEntityNames,
  PermissionFeatures,
  PermissionRequestModel,
  PermissionResponseGroup,
  PermissionResponseModel,
} from "@/iam/models/PermissionModel";
import { ReportType } from "@/reports/models";
import ReportUtil from "@/reports/utils/ReportUtil";
import ConfirmationDialog from "@/shared/components/ConfirmationDialog.vue";
import {
  GENERAL_SECTIONS,
  UserAccess,
  UserResponseModel,
} from "@/shared/models";
import { VueForm } from "@/shared/types/ExtendedVueType";
import ValidUtil from "@/shared/utils/ValidUtil";
import { cloneDeep } from "lodash";

@Component({
  components: { ConfirmationDialog },
})
export default class UserPermissionListGeneral extends Vue {
  @Prop() permissionEntity!: PermissionEntity;

  @Ref("form") form!: VueForm;

  readonly requiredRule = [
    ValidUtil.required(this.$lang("validation.required")),
  ];
  readonly requiredArray = [
    ValidUtil.requiredArray(this.$lang("validation.required")),
  ];
  readonly permissionsHeaders = [
    {
      text: this.$lang("users.value"),
      value: "value",
    },
    {
      text: this.$lang("users.features"),
      value: "features",
      width: 150,
      sortable: false,
    },
  ];
  permissionDialogVisible = false;
  localPermission = new PermissionRequestModel(this.user?.username ?? "");
  validPermission = true;

  get user(): UserResponseModel {
    return this.$store.state.usersStore.user;
  }

  get isPermissionAdding(): boolean {
    return this.$store.state.usersStore.isPermissionAdding;
  }

  get generalPermissions(): Array<PermissionResponseModel> {
    if (!this.user?.generalPermissions.length) {
      return [];
    }

    return cloneDeep(this.user.generalPermissions).sort(
      (permissionA, permissionB) => {
        if (!permissionA.isAggregator === !permissionB.isAggregator) {
          return permissionA.value.toLowerCase() <
            permissionB.value.toLowerCase()
            ? -1
            : 1;
        }

        return permissionA.isAggregator ? -1 : 1;
      }
    );
  }

  get groupedGeneralPermissions(): Array<PermissionResponseGroup> {
    return this.generalPermissions.reduce(
      (
        result: Array<PermissionResponseGroup>,
        permission: PermissionResponseModel
      ) => {
        const groupIndex = result.findIndex(
          ({ value }) => value === permission.value
        );

        if (groupIndex !== -1) {
          result[groupIndex].permissions.push(permission);
          result[groupIndex].permissions.sort(
            ({ feature: featureA }, { feature: featureB }) =>
              featureA > featureB ? 1 : -1
          );
        } else {
          result.push({
            entity: permission.entity,
            value: permission.value,
            permissions: [permission],
            isAggregator: permission.isAggregator,
          });
        }

        return result;
      },
      []
    );
  }

  get aggregatorNames(): Array<PermissionAggregatorNames> {
    return this.$store.state.usersStore.aggregatorNames;
  }

  get permissionEntityNames(): Array<PermissionEntityNames> {
    return this.$store.state.usersStore.permissionEntityNames;
  }

  get featuresByName(): Record<string, Array<UserAccess>> {
    return this.$store.getters.featuresByName;
  }

  get userFeaturesByName(): Record<string, Array<UserAccess>> {
    return this.user.generalPermissions.reduce(
      (result: Record<string, Array<UserAccess>>, { value, feature }) => {
        if (result[value]) {
          result[value].push(feature);
        } else {
          result[value] = [feature];
        }

        return result;
      },
      {}
    );
  }

  get values(): Array<{ value: string; text: string; isAggregator?: boolean }> {
    const aggregators: Array<{
      value: string;
      text: string;
      isAggregator?: boolean;
    }> =
      this.aggregatorNames
        .find(
          ({ ngacEntityName }: PermissionAggregatorNames) =>
            ngacEntityName === PermissionEntity.APP_SECTIONS
        )
        ?.aggregators.flatMap(({ name: value, type }) =>
          type === AppSectionAggregator.GENERAL_APP_SECTIONS &&
          this.featuresByName[value]?.length !==
            this.userFeaturesByName[value]?.length
            ? [
                {
                  text: value,
                  value,
                  isAggregator: true,
                },
              ]
            : []
        )
        .sort(this.sortByName) || [];
    const entities: Array<{
      value: string;
      text: string;
      isAggregator?: boolean;
    }> =
      this.permissionEntityNames
        .find(
          ({ ngacEntityName }: PermissionEntityNames) =>
            ngacEntityName === PermissionEntity.APP_SECTION
        )
        ?.ngacNames.flatMap((value: string) =>
          GENERAL_SECTIONS.includes(value) &&
          this.featuresByName[value]?.length !==
            this.userFeaturesByName[value]?.length
            ? [
                {
                  text: this.getValueTitle(value),
                  value,
                },
              ]
            : []
        )
        .sort(this.sortByName) || [];

    return aggregators.concat(entities);
  }

  get permissionFeatures(): Array<PermissionFeatures> {
    return this.$store.state.usersStore.permissionFeatures;
  }

  get features(): Array<Record<string, string>> {
    const unavailableFeatures = this.generalPermissions.reduce(
      (
        result: Array<UserAccess>,
        { value, feature }: PermissionResponseModel
      ) => {
        if (value === this.localPermission?.value) {
          result.push(feature);
        }

        return result;
      },
      []
    );
    const availableFeatures: Array<UserAccess> =
      this.permissionFeatures
        .find(
          ({ ngacName }: PermissionFeatures) =>
            ngacName === this.localPermission?.value
        )
        ?.features.filter(
          (feature) => !unavailableFeatures.includes(feature)
        ) ?? [];

    return availableFeatures.map((value) => ({
      text: this.$lang(`users.userAccess.${value.toLowerCase()}`),
      value,
    }));
  }

  @Watch("localPermission.value")
  watchPermissionEntity(newValue: string | undefined) {
    this.localPermission.isAggregator = this.values.some(
      ({ value, isAggregator }) => value === newValue && isAggregator
    );
  }

  async addPermission() {
    if (!this.form.validate()) {
      return;
    }

    await this.$store.dispatch(
      "addUserPermission",
      this.localPermission?.generalPermissionsRequest
    );

    this.permissionDialogVisible = false;
    this.localPermission = new PermissionRequestModel(this.user.username);
    this.form.resetValidation();
  }

  sortByName(
    { text: textA }: { text: string },
    { text: textB }: { text: string }
  ) {
    const nameA = textA.toLowerCase();
    const nameB = textB.toLowerCase();

    if (nameA < nameB) {
      return -1;
    }

    return nameA > nameB ? 1 : 0;
  }

  getValueTitle(value: string): string {
    return Object.values(ReportType).includes(value as ReportType)
      ? ReportUtil.getReportNameByReportType(value as ReportType)
      : this.$lang(`users.appSection.${value.toLowerCase()}`);
  }

  deletePermission(permissionId: number) {
    this.$emit("delete", permissionId);
  }
}
